<!--新建拒签-->
<template>
  <div>
    <el-tabs v-model="activeName" type="card" :before-leave="beforeLeave">
      <el-tab-pane label="新建拒签" name="first">
        <div class="newForm">
          <div class="M_H">
            <el-button class="seniorbut" @click="onSubmit">拒收确认</el-button>
          </div>
          <div class="M">
            <el-form
              ref="newForm"
              :model="newForm"
              :rules="newFormRules"
              label-width="100px"
            >
              <div class="inlineDiv">
                <div class="queryItem1">
                  <el-form-item label="快递单号:">
                    <el-select
                      v-model="expressNum"
                      filterable
                      remote
                      reserve-keyword
                      value-key="courierNumber"
                      placeholder="请输入关键词"
                      :remote-method="remoteMethod"
                      @change="SE"
                      :loading="loading"
                    >
                      <el-option
                        v-for="item in expressNumArr"
                        :key="item.courierNumber"
                        :label="item.courierNumber"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <el-button class="alonebut seniorbut" @click="QE"
                  >查 询</el-button
                >
              </div>
              <div class="inlineDiv">
                <div class="queryItem1">
                  <el-form-item label="快递单号:">
                    <el-input
                      v-model="newForm.packageCourierNumber"
                      readonly
                      placeholder="自动填写"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item label="会员号:">
                    <el-input
                      v-model="newForm.userNumber"
                      clearable
                      readonly
                      placeholder="自动填写"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item label="会员名称:">
                    <el-input
                      v-model="newForm.userName"
                      clearable
                      readonly
                      placeholder="自动填写"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="inlineDiv">
                <div class="queryItem1">
                  <el-form-item label="拒收类型:" prop="rejectionStatus">
                    <el-select
                      v-model="newForm.rejectionStatus"
                      clearable
                      placeholder="请选择拒收类型"
                    >
                      <el-option
                        v-for="(item, index) in jushouList"
                        :key="index"
                        :label="item.showName"
                        :value="item.statusVal"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item label="集运仓库:" prop="storageId">
                    <el-select
                      v-model="newForm.storageId"
                      clearable
                      placeholder="请选择活动区域"
                    >
                      <el-option
                        v-for="item in cangkuList"
                        :key="item.id"
                        :label="item.storageName"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item label="拒收备注:">
                    <el-input v-model="newForm.comment" clearable></el-input>
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="拒收处理" name="second">
        <div class="newForm">
          <div class="M_H">
            <el-button class="seniorbut" @click="dealFormSubmit"
              >拒收确认</el-button
            >
          </div>
          <div class="M">
            <el-descriptions title="基础信息" :column="3">
              {{ dealForm }}
              <el-descriptions-item label="快递单号">{{
                dealForm.packageCourierNumber
              }}</el-descriptions-item>
              <el-descriptions-item label="集运仓库">{{
                storageName
              }}</el-descriptions-item>
              <el-descriptions-item label="拒收状态">{{
                rejectionStatusShow
              }}</el-descriptions-item>
              <el-descriptions-item label="备注信息">{{
                dealForm.handleResult
              }}</el-descriptions-item>
            </el-descriptions>
            <div style="width: 60%; margin-top: 6px">
              <el-table
                border
                :data="itemVoList"
                style="width: 100%"
                size="mini"
                :header-cell-style="{ background: '#f0f0f0' }"
              >
                <el-table-column
                  type="index"
                  align="center"
                  label="序号"
                  width="50"
                ></el-table-column>
                <el-table-column
                  prop="itemName"
                  align="center"
                  label="品名"
                  min-width="80"
                ></el-table-column>
                <el-table-column
                  prop="unitPrice"
                  align="center"
                  label="单价"
                  min-width="80"
                ></el-table-column>
                <el-table-column
                  prop="quantity"
                  align="center"
                  label="数量"
                  min-width="80"
                ></el-table-column>
              </el-table>
            </div>
          </div>
          <div class="M">
            <el-form
              ref="dealForm"
              :model="dealForm"
              :rules="newFormRules"
              label-width="100px"
            >
              <div class="inlineDiv">
                <!--<div class="queryItem1">-->
                <!--<el-form-item label="拒收状态:" prop="rejectionStatus">-->
                <!--<el-input v-model="dealForm.rejectionStatus"></el-input>-->
                <!--</el-form-item>-->
                <!--</div>-->

                <div class="queryItem1">
                  <el-form-item label="拒收类型:" prop="rejectionStatus">
                    <el-select
                      v-model="dealForm.rejectionStatus"
                      clearable
                      placeholder="请选择拒收类型"
                    >
                      <el-option
                        v-for="(item, index) in jushouList"
                        :key="index"
                        :label="item.showName"
                        :value="item.statusVal"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item label="处理状态:" prop="handleStatus">
                    <el-select
                      v-model="dealForm.handleStatus"
                      clearable
                      placeholder="请选择处理状态"
                    >
                      <el-option
                        label="处理中"
                        value="chu_li_zhong:handle_status"
                      ></el-option>
                      <el-option
                        label="已处理(待审批)"
                        value="yi_chu_li:handle_status"
                      ></el-option>
                      <!-- <el-option
                        label="已处理(待付款)"
                        value="dai_fu_kuan:handle_status"
                      ></el-option>
                      <el-option
                        label="已处理(已付款)"
                        value="yi_fu_kuan:handle_status"
                      ></el-option> -->
                    </el-select>
                  </el-form-item>
                </div>
              </div>
              <div class="inlineDiv">
                <div class="queryItem1">
                  <el-form-item label="收件人:" prop="addressee">
                    <el-input
                      v-model="dealForm.addressee"
                      placeholder="请输入收件人姓名"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item label="电话:" prop="phone">
                    <el-input
                      v-model="dealForm.phone"
                      placeholder="请输入电话"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item label="地址:" prop="returnAddress">
                    <el-input
                      v-model="dealForm.returnAddress"
                      placeholder="请输入退货地址"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="inlineDiv">
                <div class="queryItem1">
                  <el-form-item label="快递公司:" prop="expressCompany">
                    <el-input
                      v-model="dealForm.expressCompany"
                      placeholder="请输入快递公司"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item label="退货单号:" prop="courierNumber">
                    <el-input
                      v-model="dealForm.courierNumber"
                      placeholder="请输入退货单号"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item label="快递金额:" prop="expressFee">
                    <el-input
                      v-model.number="dealForm.expressFee"
                      placeholder="请输入快递金额"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="queryItem1">
                <el-form-item label="处理备注:" prop="handleResult">
                  <el-input
                    v-model="dealForm.handleResult"
                    placeholder="请输入处理备注"
                  ></el-input>
                </el-form-item>
              </div>
            </el-form>
          </div>
          <div class="M">
            <el-upload
              class="upload-demo"
              :action="activeUrl"
              list-type="picture-card"
              :limit="limitCount"
              :accept="'image/*'"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :http-request="uploadSectionFile"
              :file-list="fileList"
              :class="{ hide: hideUpload }"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";

export default {
  inject: ["reload"],
  data() {
    return {
      itemVoList: [], //货物信息
      rejectionStatusShow: "", //拒收展示
      storageName: "", //仓库展示
      //  新建拒签
      activeName: "first",
      expressNum: "",
      loading: false,
      expressNumArr: [],
      newForm: {
        packageCourierNumber: "",
        userNumber: "",
        userName: "",
        rejectionStatus: "",
        storageId: "",
        comment: "",
      },
      newFormRules: {
        rejectionStatus: [
          { required: true, message: "请选择拒收类型", trigger: "change" },
        ],
        storageId: [
          { required: true, message: "请选择仓库", trigger: "change" },
        ],
        handleStatus: [
          { required: true, message: "请选择处理状态", trigger: "change" },
        ],
        expressFee: [
          { required: true, message: "请输入快递金额", trigger: "blur" },
        ],
      },
      cangkuList: [],
      singleData: {},

      //  拒签处理
      dealForm: {
        addressee: "",
        packageCourierNumber: "",
        courierNumber: "",
        expressCompany: "",
        expressFee: "",
        handleResult: "",
        handleStatus: "",
        phone: "",
        pics: [],
        rejectionId: "",
        rejectionStatus: "",
        returnAddress: "",
        storageId: "",
      },
      dealData: {},
      tableData: [],
      //     图片上传
      fileList: [],
      activeUrl: "",
      limitCount: 10,
      dialogImageUrl: "",
      dialogVisible: false,
      hideUpload: false,
      packageNum: 1,
      jushouList: [],
    };
  },
  created() {
    if (this.$route.query.status === "newAdd") {
      this.activeName = "first";
    } else {
      this.activeName = "second";
      this.myGetRefuseDetail(this.$route.query.number);
    }
    this.activeUrl = this.common.uploadUrl;
    this.getStorageList();
    this.getStatusValList();
  },
  activated() {
    if (this.$route.query.status === "newAdd") {
      this.activeName = "first";
    }
    if (this.$route.query.status === "edit") {
      this.activeName = "second";
      this.myGetRefuseDetail(this.$route.query.number);
    }
  },
  deactivated() {
    console.log("deactivated");
  },
  methods: {
    //拒收类型
    getStatusValList() {
      Api.getStatusValList({
        tableAndFieldName: "bill_rejection_v1.rejection_status",
      }).then((res) => {
        console.log(res.data.result);
        this.jushouList = res.data.result || [];
      });
    },
    //  -------------  新建拒签  ------------
    getStorageList() {
      //  获取仓库下拉列表
      Api.getStorageList().then((res) => {
        if (res.data.status === "success") {
          this.cangkuList = res.data.result || [];
          if (this.cangkuList.length > 0) {
            this.newForm.storageId = this.cangkuList[0].id;
          }
        } else {
          this.$message.error(`${res.data.message}--仓库下拉列表API`);
        }
      });
    },

    remoteMethod(query) {
      //  select框远程搜索
      if (query !== "") {
        this.loading = true;
        Api.getExpressNumData({ courierNumber: query }).then((res) => {
          if (res.data.status === "success") {
            this.loading = false;
            this.expressNumArr = res.data.result;
          }
        });
      } else {
        this.expressNumArr = [];
      }
    },
    QE() {
      //  查询按钮
      ({
        courierNumber: this.newForm.packageCourierNumber,
        userName: this.newForm.userName,
        userNumber: this.newForm.userNumber,
      } = this.singleData);
    },
    SE(val) {
      //  快递单号下拉框选中函数
      this.singleData = val;
      this.QE();
    },
    // 第一步
    onSubmit() {
      //  拒收确认
      this.$refs["newForm"].validate((valid) => {
        if (valid) {
          delete this.newForm.userName;
          delete this.newForm.userNumber;
          let parm = {
            packageCourierNumber: this.newForm.packageCourierNumber,
            rejectionStatus: this.newForm.rejectionStatus,
            storageId: this.newForm.storageId,
            comment: this.newForm.comment,
          };
          let sign = tools.getSign(parm);
          parm.sign = sign;
          Api.addRefuse(parm).then((res) => {
            this.$message.success(res.data.message);
            setTimeout(() => {
              tools.closePage();
              this.reload();
            }, 1000);
          });
        }
      });
    },

    //     -------------  拒收处理  ------------
    myGetRefuseDetail(num) {
      //  获取拒收详情
      Api.getRefuseDetail({
        packageCourierNumber: num,
      }).then((res) => {
        if (res.data.status === "success") {
          if (res.data.result.isRejection === 1) {
            res.data.result.isRejectionName = "已拒收";
          } else {
            res.data.result.isRejectionName = "未拒收";
          }
          this.fileList = [];
          if (res.data.result.picList !== null) {
            res.data.result.picList.forEach((item) => {
              this.fileList.push({
                name: item.fileName,
                url: item.url,
              });
            });
          }

          let dealForm = res.data.result || [];

          this.dealForm = {
            addressee: dealForm.addressee || "",
            packageCourierNumber: dealForm.packageCourierNumber || "",
            courierNumber: dealForm.courierNumber || "",
            expressCompany: dealForm.expressCompany || "",
            expressFee: dealForm.expressFee || "",
            handleResult: dealForm.comment || "",
            handleStatus: dealForm.handleStatus || "",
            phone: dealForm.phone || "",
            pics: dealForm.picList || [],
            rejectionId: dealForm.rejectionId || "",
            rejectionStatus: dealForm.rejectionStatus || "",
            returnAddress: dealForm.returnAddress || "",
            storageId: dealForm.storageId || "",
          };
          // this.dealForm.handleStatus = "";
          this.itemVoList = dealForm.itemVoList || [];
          this.rejectionStatusShow = dealForm.rejectionStatusShow || "";
          this.storageName = dealForm.storageName || "";
          console.log(dealForm);
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    // 拒收处理进来
    dealFormSubmit() {
      let parma = {};
      parma.pics = new Array();
      this.fileList.forEach((item) => {
        parma.pics.push(item.name);
      });
      ({
        addressee: parma.addressee,
        courierNumber: parma.courierNumber,
        expressCompany: parma.expressCompany,
        expressFee: parma.expressFee,
        handleResult: parma.handleResult,
        handleStatus: parma.handleStatus,
        phone: parma.phone,
        rejectionId: parma.rejectionId,
        rejectionStatus: parma.rejectionStatus,
        returnAddress: parma.returnAddress,
        storageId: parma.storageId,
      } = this.dealForm);

      parma.sign = tools.getSign(parma);
      Api.dealRefuse(parma).then((res) => {
        if (res.data.status === "success") {
          this.$message.success(res.data.message);
          setTimeout(() => {
            tools.closePage();
            this.reload();
          }, 1000);
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    //    ------------ 上传图片 ---------------
    handleRemove(file, fileList) {
      //  图片上传事件
      /* 移除图片 */
      this.fileList = fileList;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    uploadSectionFile(param) {
      // 上传图片
      const file = param.file;
      const fileName = file.name;
      const form = new FormData(); //  根据后台需求数据格式
      form.append("file", file); //  文件对象
      form.append("fileName", fileName); //  本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      Api.uploadFile(form).then((res) => {
        if (res.data.status === "success") {
          this.fileList.push({
            name: res.data.result.fileName,
            uid: res.data.result.redirectUrl,
            url: res.data.result.url,
          });
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    beforeLeave(an, on) {
      if (an === "second") {
        return false;
      } else if (on === "second") {
        return false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.queryItem1 {
  margin: 5px 1%;
}

.M {
  margin-bottom: 16px;
}

.hide .el-upload--picture-card {
  display: none;
}

/*.el-textarea__inner {*/
/*border: 0 !important;*/
/*}*/
.alonebut {
  height: 30px;
  margin-top: 6px;
  margin-left: 10px;
}
</style>
